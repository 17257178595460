.ReactModal__Body--open {
  overflow: hidden;
}
/*-----------------------------------------------------------------------------------*/
/* Base
/*-----------------------------------------------------------------------------------*/
body {
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.linkOver {
  width: 100%;
  display: "block";
}

/*-----------------------------------------------------------------------------------*/
/* WordPress Content
/*-----------------------------------------------------------------------------------*/
.entry .intro p {
  font-weight: 500!important;
}
.entry p {
  font-weight: 500!important;
}

.entry a {
  text-decoration: underline;
}
.entry a:hover {
  text-decoration: none;
}

.entry b,
.entry strong {
  font-weight: 600;
}

.entry blockquote,
.intro blockquote {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  padding-left: 1.5rem !important;
  border-left: 2px solid #155fa2 !important;
}
.entry blockquote p,
.intro blockquote p {
  font-size: larger !important;
  color: #155fa2 !important;
  font-weight: 600 !important;
  line-height: 1.8rem !important;
  margin-bottom: 1rem !important;
}
.entry blockquote footer,
.intro blockquote footer {
}
.entry blockquote footer cite,
.intro blockquote footer cite {
  font-size: smaller !important;
  font-style: italic !important;
  margin-bottom: 1.5em !important;
  text-decoration: none !important;
  font-weight: 500 !important;
}

.entry hr {
  margin-top: 24px;
  margin-bottom: 32px;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  height: 1px;
}

.entry dl,
.entry ol,
.entry ul {
  padding-left: 20px;
}

.entry li {
  margin-bottom: 8px;
}
.entry li ul,
.entry li ol {
  margin-top: 10px;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 0;
}

.entry img,
.entry a img {
  margin-bottom: 1.5em;
  max-width: 100%;
  height: auto;
}

.entry p img {
  margin-top: 1.5em;
}

.entry .wp-caption {
  width: 100% !important;
}
.entry .wp-caption img {
  margin-bottom: 0 !important;
}

.entry .wp-caption .wp-caption-text {
  font-size: smaller;
  font-style: italic;
  margin-bottom: 1.5em;
}

.entry .alignleft,
.entry img.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}
.entry .alignright,
.entry img.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}
.entry .aligncenter,
.entry img.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.entry .button-editor {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  position: relative !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: #155fa2 !important;
  border-radius: 30px !important;
  cursor: pointer !important;
  display: -webkit-inline-box !important;
  display: -moz-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
  -webkit-align-items: center !important;
  align-items: center !important;
  line-height: 1 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-align: center !important;
  text-decoration: none !important;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-weight: 500 !important;
  letter-spacing: 0.15rem !important;
  text-transform: uppercase !important;
  -webkit-transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
    opacity 0.1s ease-in-out !important;
  -moz-transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
    opacity 0.1s ease-in-out !important;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
    opacity 0.1s ease-in-out !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
  background-color: transparent !important;
  color: #155fa2 !important;
  font-size: 12px !important;
  padding-bottom: 12px !important;
  padding-top: 12px !important;
  font-style: normal !important;
}

.entry .button-editor:hover {
  background-color: #2378c5 !important;
  color: #ffffff !important;
}

.entry a .button-editor,
.entry .button-editor a,
.entry .button-editor strong,
.entry .button-editor em,
.entry .button-editor a strong,
.entry .button-editor a em,
.entry a .button-editor strong,
.entry a .button-editor em {
  font-weight: 500 !important;
  font-style: normal !important;
  text-decoration: none;
}

/*-----------------------------------------------------------------------------------*/
/* Video */
/*-----------------------------------------------------------------------------------*/
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  overflow: hidden;
  margin-bottom: 1rem;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*-----------------------------------------------------------------------------------*/
/* Intro Text */
/*-----------------------------------------------------------------------------------*/
.intro p:first-of-type {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 2rem;
}

@media (max-width: 576px) {
  .intro p:first-of-type {
    font-weight: 400;
    font-size: 15px;
    line-height: 22.5px;
    margin-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  .small {
    display: block;
  }

  .large {
    display: none;
  }
}

@media (min-width: 768px) {
  .small {
    display: none;
  }

  .large {
    display: block;
  }
}

/*-----------------------------------------------------------------------------------*/
/* Map */
/*-----------------------------------------------------------------------------------*/
.map {
  width: 100%;
  height: 300px;
}

@media (min-width: 768px) {
  .map {
    height: 100%;
    min-height: 600px;
  }
}
.map {
  width: 100%;
  height: 100%;
  min-height: 600px;
}

.SocialMediaShareButton {
  display: block;
  margin-right: 10px;
}

.Demo__suggestion-icon {
  margin-right: 8px;
}

.fa.fa-map-marker.header {
  color: #e53935;
}

.Demo__search-input,
.Demo__search-input:focus,
.Demo__search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 2px;
  outline: none;
}

.places-group {
  position: relative;
}

.Demo__autocomplete-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
  position: absolute;
  top: 100%;
  background-color: white;
  width: 100%;
  z-index: 1000;
}

.Demo__suggestion-item {
  padding: 2px;
  text-align: left;
  font-size: 13px;
}

.Demo__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.Demo__dropdown-footer-image {
  display: inline-block;
  width: 80px;
}

.Demo__spinner {
  color: #18bc9c;
  font-size: 30px;
}

@media (max-width: 480px) {
  .autocomplete-container {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .alert {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    -webkit-animation: fadein 0.75s;
    -moz-animation: fadein 0.75s;
    -ms-animation: fadein 0.75s;
    -o-animation: fadein 0.75s;
    animation: fadein 0.75s;
  }

  .btn {
    margin-top: 1.5rem;
  }
}

.geocoding-results {
  text-align: left;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*-----------------------------------------------------------------------------------*/
/* Twitter Bootstrap Card Col Layout adjust for stickers */
/*-----------------------------------------------------------------------------------*/
.card-columns.columns-2 {
  column-count: 2;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 2;
  }
}
@media (min-width: 992px) {
  .card-columns {
    column-count: 3;
  }
}

.asideTwitter {
  font-weight: 500!important;
}
.asideTwitter a {
  color: #155fa2;
}
.asideTwitter a:hover {
  text-decoration: underline;
}

/*-----------------------------------------------------------------------------------*/
/* Search Styles */
/*-----------------------------------------------------------------------------------*/
.ais-Pagination__root {
  width: 100%;
  text-align: center;
  border: none;
  border-radius: 0;
  box-shadow: none;
  border-top: 1px solid #eeeeee;
  margin-top: 24px;
  padding-top: 16px;
}
.ais-Pagination__item {
  border-radius: 50%;
}
.ais-Pagination__itemSelected {
  background-color: #155fa2;
}
.ais-Pagination__item:hover {
  background-color: #f6f6f6;
}
.ais-Pagination__item:hover .ais-Pagination__itemLink {
  color: #333333;
}
.ais-SearchBox__input {
  box-shadow: none;
  border-radius: 2px;
  border-color: #eeeeee;
  color: #333;
}
.ais-RefinementList__itemCount {
  color: #333333;
  background-color: #f6f6f6;
}
.ais-RefinementList__items {
  margin-top: 24px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 16px;
}
.ais-RefinementList__item {
  color: #155fa2;
  margin-top: 8px;
}
.ais-RefinementList__item
  input[type="checkbox"]:checked
  ~ .ais-RefinementList__itemBox {
  background-color: #155fa2;
  border-color: #155fa2;
}

/*-----------------------------------------------------------------------------------*/
/* Scrolling SubNav */
/*-----------------------------------------------------------------------------------*/
.navWrapper.submenu {
  position: relative;
  display: block;
  white-space: nowrap;
  overflow: scroll;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.navWrapper.submenu::-webkit-scrollbar {
  display: none;
}
.navWrapper.submenu li:last-child {
  padding-right: 32px;
}

.navWrapperContainer.submenu {
  position: relative;
}
.navWrapperContainer.submenu::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 32px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 99%,
    rgba(255, 255, 255, 1) 100%
  );
}

/*-----------------------------------------------------------------------------------*/
/* History Page Timeline */
/*-----------------------------------------------------------------------------------*/

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 2px;
  background: #e8e8e8;
  left: 50%;
  margin-left: -1px;
}

@media (max-width: 767px) {
  .timeline:before {
    left: 32px;
  }
}

.mobileTop {
  display: block;
}

.desktopTop {
  display: none;
}

@media (min-width: 992px) {
  .mobileTop {
    display: none;
  }

  .desktopTop {
    display: block;
  }
}

.visible-sm {
  display: block;
}

.hidden-sm {
  display: none;
}

@media (min-width: 1200px) {
  .visible-sm {
    display: none;
  }

  .hidden-sm {
    display: block;
  }
}
